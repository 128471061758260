import { useState, useMemo } from 'react';
import useTranslations from '../../../../i18n/useTranslations';

import { Chart } from './Chart';
import { Consumption } from './Consumption';
import styles from './Detail.module.css';

const Detail = ({ bills }) => {
  const { t, formatDate } = useTranslations();

  const { maxValue, average, formattedBills, initialActiveItem, totalSavings } = useMemo(() => {
    const maxValue = Math.max(...bills.map((bill) => bill.cost)) + 25;
    const average = bills.reduce((acc, bill) => acc + (bill.cost * bill.n_days), 0) / bills.reduce((acc, bill) => acc + bill.n_days, 0);
    const totalSavings = bills.reduce((acc, bill) => acc + (bill.cost_wo_zenvi - bill.cost), 0);
    const formattedBills = bills.map((bill) => ({
      ...bill,
      issue_date_month: formatDate(bill.issue_date),
      issue_date_month_long: formatDate(bill.issue_date, { month: 'long' }),
    }));

    formattedBills.sort((a, b) => new Date(a.issue_date) - new Date(b.issue_date));

    const initialActiveItem = 0;

    return { maxValue, average, formattedBills, initialActiveItem, totalSavings };
  }, [bills, formatDate]);

  const [activeItem, setActiveItem] = useState(initialActiveItem);

  return (
    <div className={styles.panel}>
      <h3 className={styles.title}>{t('contract:bill_view:consumption')}</h3>
      <div className={styles.graphContent}>
        <Chart
          bills={formattedBills}
          maxValue={maxValue}
          average={average}
          handleActive={setActiveItem}
          activeItem={activeItem}
        />
        <Consumption activeItem={formattedBills[activeItem]} average={average} totalSavings={totalSavings} />
      </div>
    </div>
  );
};

export default Detail;
