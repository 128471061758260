import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';

import useTranslations from '../../i18n/useTranslations';
import { ROUTES } from '../../routes';
import services from '../../services';
import { useAuth, useForm } from '../../context';
import { LOGIN_FORM_INPUTS } from '../../constants';
import { loginSchema } from '../../validationSchemas';

import { PublicLayout } from '../../Layout';
import { FormikInput } from '../Input';
import { Button } from '../Button';
import { Link } from '../Link';
import styles from './Login.module.css';
import stylesForm from '../Form/Form.module.css';

import { SocialLogin } from '../SocialLogin';

const { registerLogin } = services;

const Login = () => {
  const { refreshContext } = useForm();
  const { checkToken } = useAuth();
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslations();

  const handleNextStep = async (values) => {
    try {
      const res = await registerLogin(values);
      localStorage.setItem('token', res.access_token);
      await checkToken();
      const {
        userIsAdmin,
        userDetailsRegistered,
        anyEstimatedBill,
        isTempPassword,
      } = await refreshContext();
  
      if (userIsAdmin) {
        navigate(ROUTES.ADMIN);
        return;
      }

      if (!userDetailsRegistered){
        navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_NEW_TYPE}`);
        return;
      }

      if (!anyEstimatedBill){
        navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_ADDRESS}`);
        return;
        
      }
      navigate(ROUTES.DASHBOARD);
      return;
    } catch (error) {
      setError(t('form:error:invalid_credentials'));
    }
  };


  return (
    <PublicLayout>
      <section className={styles.root}>
        <header className={styles.header}>
          <h1 className={styles.heading}>{t('login:heading')}</h1>
        </header>
        <div className={styles.content}>
          <Formik
            initialValues={LOGIN_FORM_INPUTS}
            validationSchema={loginSchema}
            onSubmit={handleNextStep}
          >
            {({ errors, touched }) => (
              <Form className={stylesForm.form} noValidate>
                <div className={stylesForm.formGroup}>
                  <div>
                    <FormikInput
                      type="email"
                      label="input:email"
                      name="email"
                      error={errors['email']}
                      touched={touched['email']}
                      inputMode="email"
                      required
                    />
                  </div>
                  <div>
                    <FormikInput
                      type="password"
                      label="input:password"
                      name="password"
                      error={errors['password']}
                      touched={touched['password']}
                      inputMode="text"
                      required
                    />
                  </div>

                  {error && <p className={stylesForm.errorMsg}>{error}</p>}

                  <SocialLogin />

                  <p className={stylesForm.formText}>

                    {t('action:forgotten_password')}{' '}
                    <Link to={`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_NEW_PASSWORD_REQUEST}`}>{t('action:regenerate_password')}</Link>
                  </p>

                  <p className={stylesForm.formText}>
                    {t('account_invitation:no_invitation_code')}{' '}
                    <Link to={ROUTES.ACCOUNT_WAITLIST}>{t('action:add_to_waitlist')}</Link>
                  </p>
                </div>

                <div className={stylesForm.formActions}>
                  <Button isFullWidth type="submit">
                    {t('action:continue')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </PublicLayout>
  );
};

export default Login;
