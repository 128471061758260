import { forwardRef } from 'react';

import useTranslations from '../../../../i18n/useTranslations';

import { Icon, Icons } from '../../../Icon';
import styles from './ContractDialog.module.css';

const ContractDialog = forwardRef(({ onClose, children }, ref) => {
  const { t } = useTranslations();

  const handleClickOutside = (event) => {
    if (event.target === ref.current) {
      onClose();
    }
  };

  return (
    <dialog
      ref={ref}
      className={styles.root}
      onKeyDown={(event) => event.key === 'Escape' && onClose(event)}
      onClick={handleClickOutside}
    >
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <button className={styles.close} type="button" onClick={onClose}>
            <span className="visually-hidden">{t('action:close')}</span>
            <Icon size={32} name={Icons.Close} />
          </button>
        </header>
        <div className={styles.content}>{children}</div>
      </div>
    </dialog>
  );
});

export default ContractDialog;
