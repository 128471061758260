import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useTranslations from '../../../i18n/useTranslations';
import { ROUTES } from '../../../routes';
import { useForm } from '../../../context';
import services from '../../../services';
import { Icon } from '../../Icon';
import { Input } from '../../Input';
import { Button } from '../../Button';
import { Stepper } from '../Stepper';
import CloseIcon from './close-icon.svg';
import { Dialog } from './Dialog';
import styles from './AccountIBAN.module.css';

const { registerUserWithIBAN, generateDropboxSignUrl, isUserOnboarded } = services;

const AccountIBAN = () => {
  const { contractResult, setContractResult, setSignUrl } = useForm();
  const navigate = useNavigate();
  const { t } = useTranslations();
  const dialogRef = useRef(null);

  const contractsNotOnboarded = (contractResult || []).filter(
    (contract) => !contract.iban_onboarded
  );

  const [ibans, setIbans] = useState(() => (contractsNotOnboarded || []).map(() => ''));
  const masterIndex = 0;

  useEffect(() => {
    const handleContractResult = async () => {
      if (contractResult?.every((contract) => contract.iban_onboarded === true)) {
        try {
          const { success } = await isUserOnboarded(contractResult);
          if (!success) {
            const { sign_url } = await generateDropboxSignUrl(contractResult);
            setSignUrl(sign_url);
            navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_AUTH_SIGN}`);
          } else {
            navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_SUCCESS}`);
          }
        } catch (error) {
          console.error('Error processing contract result:', error);
          navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_SUCCESS}`);
        }
      }
    };
  
    handleContractResult();
  }, [contractResult, navigate]);

  const handleDialog = () => {
    dialogRef.current.showModal();
  };

  const handleCloseDialog = () => {
    dialogRef.current.close();
  };

  const handleChange = (index, event) => {
    const newValues = [...ibans];
    newValues[index] = event.target.value || '';

    setIbans(newValues);

    if (index === masterIndex) {
      for (let i = 0; i < contractsNotOnboarded.length; i++) {
        if (i !== index) {
          newValues[i] = event.target.value || '';
        }
      }
      setIbans(newValues);
    }
  };

  const handleRemoveBox = (index) => {
    setContractResult((prev) => {
      const newFormData = [...prev];
      newFormData.splice(index, 1);
      return newFormData;
    });
  };

  const handleNextStep = async (e) => {
    e.preventDefault();

    const updatedContractResult = contractsNotOnboarded.map((contract, index) => ({
      ...contract,
      ...{ iban: ibans[index] },
    }));

    const contractResultWithIBAN = updatedContractResult
      .filter(({ iban }) => iban !== null)
      .filter((contract) => contract.iban !== undefined)
      .map(({ contract_id, product_type, iban }) => {
        return {
          contract_id,
          product_type,
          iban,
        };
      });

    try {
      await registerUserWithIBAN(contractResultWithIBAN);

      const { success } = await isUserOnboarded(contractResultWithIBAN);

      if (!success) {
        const { sign_url } = await generateDropboxSignUrl(contractResultWithIBAN);
        setSignUrl(sign_url);
        navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_AUTH_SIGN}`);
      } else {
        navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_SUCCESS}`);
      }
    } catch (err) {
      console.error(t('form:error:onboarding:iban'), err);
    }
  };

  return (
    <Stepper currentStep={3}>
      <section className={styles.root}>
        <header className={styles.header}>
          <h1 className={styles.heading}>{t('account_iban:heading')}</h1>
          <p className={styles.subheading}>{t('account_iban:subheading')}</p>
        </header>
        <div className={styles.content}>
          <form className={styles.form} onSubmit={handleNextStep}>
            <fieldset className={styles.fieldset}>
              <div className={styles.boxWrapper}>
                {contractsNotOnboarded?.map((factura, index) => (
                  <div key={factura.contract_id} className={styles.box}>
                    <div className={styles.boxHeader}>
                      <Icon size={48} name={factura.product_type} />
                      <h2 className={styles.boxHeading}>{factura.address}</h2>
                      <button
                        type="button"
                        className={styles.boxHeaderButton}
                        onClick={() => handleRemoveBox(index)}
                      >
                        <span className="visually-hidden">{t('action:remove')}</span>
                        <img width="32" height="32" src={CloseIcon} alt="" />
                      </button>
                    </div>
                    <div className={styles.boxContent}>
                      <Input
                        id={`iban-${index}`}
                        name={`iban-${index}`}
                        label={t('input:iban')}
                        value={ibans[index] || ''}
                        required
                        onChange={(event) => handleChange(index, event)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <button type="button" className={styles.button} onClick={handleDialog}>
                {t('account_iban:dialog:button')}
              </button>
            </fieldset>
            <Button isFullWidth type="submit">
              {t('action:continue')}
            </Button>
          </form>
          <Dialog ref={dialogRef} onClose={handleCloseDialog} />
        </div>
      </section>
    </Stepper>
  );
};

export default AccountIBAN;
