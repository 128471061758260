import PropTypes from 'prop-types';
import getIcon from './getIcon';

const IconPropTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
};

const Icon = ({ name, size = 48, ...rest }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      {...rest}
    >
      {getIcon(name)}
    </svg>
  );
};

Icon.propTypes = IconPropTypes;

export default Icon;
