import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { useForm } from './FormContext';

export const AppReadyContext = createContext();

export const AppReadyProvider = ({ children }) => {
  const { loading: authLoading, isAuthenticated } = useAuth();
  const { routingContextReady } = useForm();
  const [appReady, setAppReady] = useState(false);
  const [readyPromise, setReadyPromise] = useState(Promise.resolve()); // Promise for readiness

  useEffect(() => {
    if (!authLoading && (!isAuthenticated || routingContextReady)) {
      setAppReady(true);
      setReadyPromise(Promise.resolve()); // Resolve when app is ready
    } else {
      setAppReady(false);
      setReadyPromise(new Promise((resolve) => {
        // Will resolve when app becomes ready
        const interval = setInterval(() => {
          if (!authLoading && (!isAuthenticated || routingContextReady)) {
            setAppReady(true);
            resolve();
            clearInterval(interval);
          }
        }, 100); // Check every 100ms
      }));
    }
  }, [authLoading, isAuthenticated, routingContextReady]);

  return (
    <AppReadyContext.Provider value={{ appReady, waitForReady: () => readyPromise }}>
      {children}
    </AppReadyContext.Provider>
  );
};

export const useAppReady = () => useContext(AppReadyContext);