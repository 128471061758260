import useTranslations from '../../i18n/useTranslations';

import styles from './Checkbox.module.css';

const Checkbox = ({ id, isOpen, isChecked, onCheckboxChange }) => {
  const { t } = useTranslations();

  return (
    <div className={styles.checkbox}>
      <input
        id={id}
        name={id}
        type="checkbox"
        className={styles.input}
        disabled={!isOpen}
        checked={isChecked}
        onChange={onCheckboxChange}
      />
      <label htmlFor={id} className={styles.label}>
        {t('account_estimated_results:card:confirm:notify_lower_price')}
      </label>
    </div>
  );
};

export default Checkbox;
