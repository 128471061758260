import useTranslations from '../../../../../i18n/useTranslations';

import styles from './Consumption.module.css';

const Consumption = ({ activeItem, average, totalSavings }) => {
  const { t, formatNumber } = useTranslations();

  const displayValue = totalSavings >= 0 ? totalSavings : average;
  const displayLabel = totalSavings >= 0 ? t('contract:bill_view:total_savings') : t('contract:bill_view:average');

  return (
    <div className={styles.root} role="region" aria-label={t('contract:bill_view:details')}>
      <div className={`${styles.item} ${styles.itemDismiss}`}>
        <span className={styles.value}>{formatNumber(displayValue)}</span>
        <span className={styles.label}>{displayLabel}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.value}>{formatNumber(activeItem.cost)}</span>
        <span className={styles.label}>{activeItem.issue_date_month_long}</span>
      </div>
    </div>
  );
};

export default Consumption;
