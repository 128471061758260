import { DashboardHeader } from './Header';
import { DashboardFooter } from './Footer';
import styles from './DashboardLayout.module.css';

const DashboardLayout = ({ withFooter = false, children }) => {
  return (
    <main className={styles.root}>
      <DashboardHeader />
      <article className={styles.wrapper}>{children}</article>
      {withFooter ? <DashboardFooter /> : null}
    </main>
  );
};

export default DashboardLayout;
