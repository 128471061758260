import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import AppIntlProvider from './i18n/AppIntlProvider';
import { ROUTES } from './routes';
import { AuthProvider, ContractsProvider, FormProvider, NotificationsProvider, AppReadyProvider } from './context';

import { PublicLayout, DashboardLayout, BreadcrumbLayout } from './Layout';
import {
  ErrorPage,
  OnboardingRoute,
  PrivateRoute,
  PublicRoute,
  Onboarding,
  Admin,
  Dashboard,
  Contract,
  Bill,
  Login,
} from './components';

import './index.css';

const App = () => (
  <AppIntlProvider>
    <AuthProvider>
      <ContractsProvider>
        <Router>
          <Routes>
            <Route
              path={ROUTES.ROOT}
              element={
                <FormProvider>
                  <AppReadyProvider>
                    <PublicRoute>
                      <Login />
                    </PublicRoute>
                  </AppReadyProvider>
                </FormProvider>
              }
            />

            <Route
              path={ROUTES.ACCOUNT}
              element={
                <FormProvider>
                  <OnboardingRoute>
                    <Onboarding />
                  </OnboardingRoute>
                </FormProvider>
              }
            />

            <Route
              path={ROUTES.DASHBOARD}
              element={
                <PrivateRoute>
                  <FormProvider>
                    <NotificationsProvider>
                      <DashboardLayout withFooter>
                        <Dashboard />
                      </DashboardLayout>
                    </NotificationsProvider>
                  </FormProvider>
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.CONTRACT}
              element={
                <PrivateRoute>
                  <DashboardLayout>
                    <Contract />
                  </DashboardLayout>
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.BILL}
              element={
                <PrivateRoute>
                  <BreadcrumbLayout>
                    <Bill />
                  </BreadcrumbLayout>
                </PrivateRoute>
              }
            />

            <Route
              path={ROUTES.ADMIN_OPS}
              element={
                <PublicLayout>
                  <Admin />
                </PublicLayout>
              }
            />

            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Router>
      </ContractsProvider>
    </AuthProvider>
  </AppIntlProvider>
);

export default App;
