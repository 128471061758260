import OnboardingServices from './OnboardingServices';
import AdminServices from './AdminServices';
import UserServices from './UserServices';

const services = {
  ...OnboardingServices,
  ...AdminServices,
  ...UserServices,
};

export default services;
