import { useState } from 'react';
import { Formik, Form } from 'formik';

import useTranslations from '../../../../i18n/useTranslations';
import { GET_IN_TOUCH } from '../../../../constants';
import { getInTouchSchema } from '../../../../validationSchemas';
import services from '../../../../services';

import { Button } from '../../../Button';
import { FormikInput } from '../../../Input';
import { Feedback } from '../Feedback';
import stylesForm from '../../../Form/Form.module.css';
import styles from './GetInTouch.module.css';

const { getInTouch } = services;

const GetInTouch = ({ contract }) => {
  const { t } = useTranslations();
  const [feedback, setFeedback] = useState('');

  const handleSubmit = async (values, { resetForm }) => {
    const response = await getInTouch({
      contract_id: contract.contract_id.toString(),
      product_type: contract.product_type,
      ...values,
    });

    if (response.status === 200) {
      setFeedback('success');
      resetForm();
    } else {
      setFeedback('error');
    }
  };

  if (feedback === 'success') {
    return (
      <Feedback
        type="success"
        heading={t('contract:getInTouch_contract:feedback:success')}
        subheading={t('contract:getInTouch_contract:feedback:success_subheading')}
      />
    );
  }

  if (feedback === 'error') {
    return (
      <Feedback
        type="error"
        heading={t('contract:getInTouch_contract:feedback:error')}
        subheading={t('contract:getInTouch_contract:feedback:error_subheading')}
      />
    );
  }

  return (
    <>
      <h2 className={styles.heading}>{t('contract:getInTouch_contract:heading')}</h2>
      <p className={styles.subheading}>{t('contract:getInTouch_contract:subheading')}</p>
      <div className={styles.action}>
        <Formik
          initialValues={GET_IN_TOUCH}
          validationSchema={getInTouchSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, resetForm }) => (
            <Form className={stylesForm.form} noValidate>
              <div className={stylesForm.formGroup}>
                <div>
                  <FormikInput
                    as="textarea"
                    label="input:request"
                    name="request"
                    error={errors['request']}
                    touched={touched['request']}
                    inputMode="text"
                    required
                  />
                </div>
              </div>
              <div className={stylesForm.formActions}>
                <Button isFullWidth type="submit">
                  {t('action:send')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default GetInTouch;
