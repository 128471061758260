import axios from 'axios';

const domain = process.env.REACT_APP_BACKEND_URL || 'https://backend-zenvi-5zv6ce4weq-no.a.run.app';
const subdomain = 'api';

const verifyToken = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${domain}/${subdomain}/verify_token`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status != 200) {
      throw new Error('Token verification failed');
    }
    return response.data.isValid;
  } catch (error) {
    console.error('Error verifying token:', error);
    return false;
  }
};

const registerLogin = async (body) => {
  try {
    const response = await axios.post(`${domain}/${subdomain}/register_login`, body);
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const newPasswordRequest = async (body) => {
  try {
    const response = await axios.post(`${domain}/${subdomain}/new_password_request`, body);
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const registerUserWithIBAN = async (body) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/insert_iban`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    console.error('Error posting form data:', error);
    throw error;
  }
};

const isIBANRegistered = async (body) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/is_iban_registered`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const onboardUser = async (body) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/onboard_user`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const isUserOnboarded = async (body) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/is_user_onboarded`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const generateDropboxSignUrl = async (body) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${domain}/${subdomain}/generate_dropbox_authorization`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const saveUserInfo = async (body) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/add_personal_details`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const createNewPassword = async (body) => {
  try {
    console.log("This is the body my love:", body)
    const response = await axios.post(`${domain}/${subdomain}/create_new_password`, body);
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const saveUserContracts = async (data) => {
  const formData = new FormData();
  data.facturas.forEach((factura, index) => {
    if (factura.file && Array.isArray(factura.file)) {
      factura.file.forEach((file_i, subindex) => {
        formData.append(`facturas_${index}_file_${subindex}`, file_i);
      });
    }
    formData.append(`facturas_${index}_address_street`, factura.address_street);
    formData.append(`facturas_${index}_address_number`, factura.address_number);
    formData.append(`facturas_${index}_address_stair`, factura.address_stair);
    formData.append(`facturas_${index}_address_floor_number`, factura.address_floor_number);
    formData.append(`facturas_${index}_address_door_number`, factura.address_door_number);
    formData.append(`facturas_${index}_city`, factura.city);
    formData.append(`facturas_${index}_zip_code`, factura.zip_code);
    formData.append(
      `facturas_${index}_previous_account_holder_name`,
      factura.previous_account_holder_name
    );
    formData.append(`facturas_${index}_user_is_contract_holder`, factura.user_is_contract_holder);
  });
  Object.keys(data).forEach((key) => {
    if (key !== 'facturas') {
      formData.append(key, data[key]);
    }
  });

  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/get_bill_estimates`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const computeBillEstimates = async (files) => {
  const token = localStorage.getItem('token');
  const formData = new FormData();
  files.forEach((file, index) => {
    formData.append(`facturas_${index}_file`, file);
  });

  const response = await axios.post(`${domain}/${subdomain}/compute_bill_estimate`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

const saveGasBills = async (email, token, files) => {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('token', token);
  files.forEach((file, index) => {
    formData.append(`facturas_${index}_file`, file);
  });

  const response = await axios.post(`${domain}/${subdomain}/save_gas_files`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
};

const checkIfAnyEstimatedBill = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${domain}/${subdomain}/check_if_any_estimated_bill`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const checkIfUserDetailsRegistered = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${domain}/${subdomain}/check_if_user_details_registered`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const getContractsWOIban = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${domain}/${subdomain}/get_contracts_with_no_iban`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const getComputedBillEstimates = async (body) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/get_computed_bill_estimates`, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    console.error('Error getting in touch:', error);
    throw error;
  }
};

const createSignTemplate = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${domain}/${subdomain}/create_user_template`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error('Error getting notifications:', error);
    throw error;
  }
};

const checkEmailTempPassword = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${domain}/${subdomain}/check_if_email_temp_password`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error('Error getting notifications:', error);
    throw error;
  }
};

const removeContracts = async (body) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/remove_contract`, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error removing contract:', error);
    throw error;
  }
};

const inviteFriend = async (body) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/invite_friend`, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error removing contract:', error);
    throw error;
  }
};

export default {
  verifyToken,
  registerLogin,
  newPasswordRequest,
  saveUserInfo,
  saveUserContracts,
  isIBANRegistered,
  registerUserWithIBAN,
  saveGasBills,
  generateDropboxSignUrl,
  isUserOnboarded,
  onboardUser,
  createNewPassword,
  checkIfUserDetailsRegistered,
  checkIfAnyEstimatedBill,
  getContractsWOIban,
  getComputedBillEstimates,
  createSignTemplate,
  checkEmailTempPassword,
  computeBillEstimates,
  removeContracts,
  inviteFriend,
};
