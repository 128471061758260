import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { useDropzone } from 'react-dropzone';

import useTranslations from '../../../../i18n/useTranslations';
import { MAX_TOTAL_SIZE_MB } from '../../../../constants';
import { fileSchema } from '../../../../validationSchemas';

import { Icon, Icons } from '../../../Icon';
import stylesForm from '../../../Form/Form.module.css';
import styles from './UploadFile.module.css';

const UploadFile = forwardRef(
  ({ name, handleFileUpload, error, touched, onReset, setError, setTouched, isLoading }, ref) => {
    const [files, setFiles] = useState([]);
    const { t } = useTranslations();

    useImperativeHandle(ref, () => ({
      resetFiles,
    }));

    const onDrop = (acceptedFiles) => {
      fileSchema
        .validate(acceptedFiles)
        .then(() => {
          setFiles(acceptedFiles);
          handleFileUpload({ target: { files: acceptedFiles } });
        })
        .catch((validationError) => {
          setError(validationError.message);
          setTouched({ file: true });
        });
    };

    const onRemoveFile = (fileToRemove, e) => {
      e.stopPropagation();
      const updatedFiles = files.filter((file) => file !== fileToRemove);
      setFiles(updatedFiles);
      handleFileUpload({ target: { files: updatedFiles } });
    };

    const resetFiles = () => {
      setFiles([]);
      handleFileUpload({ target: { files: [] } });
      if (onReset) onReset();
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
    });

    return (
      <div className={styles.root}>
        <div
          className={`${styles.fileUploader} ${isDragActive ? styles.dragOver : ''} ${
            Boolean(files.length) ? styles.hasFiles : ''
          } ${isLoading ? styles.isLoading : ''}`}
          {...getRootProps()}
        >
          <Icon size={24} name={Icons.Document} />
          <input name={name} {...getInputProps()} />
          {files.length === 0 ? (
            <p>{t('action:upload')}</p>
          ) : (
            <div className={styles.filesContainer}>
              {files.map((file) => (
                <button
                  key={`${file.name}-${file.path}`}
                  className={styles.removeButton}
                  onClick={(e) => onRemoveFile(file, e)}
                >
                  <Icon size={24} name={Icons.Close} />
                  <span className={styles.fileName}>{file.name}</span>
                  {isLoading ? (
                    <Icon size={20} name={Icons.Loader} className={styles.loader} />
                  ) : (
                    <Icon size={20} name={Icons.Check} className={styles.icon} />
                  )}
                </button>
              ))}
            </div>
          )}
        </div>
        {error && touched && (
          <div className={stylesForm.errorMsg}>{t(error, { size: MAX_TOTAL_SIZE_MB })}</div>
        )}
      </div>
    );
  }
);

export default UploadFile;
