export const parseGraphData = (contracts, formatDate) => {
  const COST_THRESHOLD = 0;

  const initialGraphData = {
    max_value: 0,
    inputs: [],
  };

  if (!contracts?.length) {
    return initialGraphData;
  }

  const costsByDate = new Map();

  contracts.forEach((contract) => {
    contract.issue_date.forEach((date, index) => {
      const formattedDate = formatDate(date);
      const formattedMonthYear = formatDate(date, { month: 'long', year: 'numeric' });
      const existingData = costsByDate.get(formattedDate) || {
        cost: 0,
        cost_wo_zenvi: 0,
        issue_date: formattedDate,
        full_date: formattedMonthYear,
      };

      existingData.cost += contract.cost[index];
      existingData.cost_wo_zenvi += contract.cost_wo_zenvi[index];
      costsByDate.set(formattedDate, existingData);
    });
  });

  const allCosts = Array.from(costsByDate.values()).flatMap((data) => [
    data.cost,
    data.cost_wo_zenvi,
  ]);
  const maxValue = Math.floor(Math.max(...allCosts) + COST_THRESHOLD);

  return {
    max_value: maxValue,
    inputs: Array.from(costsByDate.values()),
  };
};
