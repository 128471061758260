import { Link, useParams } from 'react-router-dom';

import useTranslations from '../../../../i18n/useTranslations';
import { ROUTE_PARAMS, ROUTES } from '../../../../routes';

import styles from './List.module.css';

const List = ({ contractWithBills }) => {
  const { t, formatNumber, formatDate } = useTranslations();
  const { type, id } = useParams();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <span>{t('contract:bill_view:month')}</span>
        <span>{t('contract:bill_view:supplier')}</span>
        <span>{t('contract:bill_view:amount')}</span>
        <span>&nbsp;</span>
      </div>
      <ul className={styles.list}>
        {contractWithBills.bills.map((bill) => {
          const { id: billId, issue_date, url, supplier, cost } = bill;
          const date = formatDate(issue_date, { month: 'long' });

          const isEmpty = !url;

          if (isEmpty) {
            return;
          }

          return (
            <li className={styles.listItemWrapper} key={billId}>
              <Link
                className={styles.listItem}
                to={ROUTES.BILL.replace(ROUTE_PARAMS.TYPE, type)
                  .replace(ROUTE_PARAMS.CONTRACT_ID, id)
                  .replace(ROUTE_PARAMS.BILL_ID, billId)}
              >
                <span>{date}</span>
                <span>{supplier}</span>
                <span>{formatNumber(cost)}</span>
              </Link>
              <Link
                className={styles.downloadButton}
                to={url}
                target="_blank"
                rel="noreferrer noopener"
              >
                {t('action:download')}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default List;
