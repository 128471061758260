import PropTypes from 'prop-types';
import useTranslations from '../../../i18n/useTranslations';

import { ROUTES, ROUTE_PARAMS } from '../../../routes';
import { Icon, Icons } from '../../Icon';
import { Link } from '../../Link';
import styles from './Card.module.css';

const getLastInvoiceCost = (cost) => {
  return cost.toReversed().find((value) => value > 0);
};

const CardPropTypes = {
  contract: PropTypes.shape({
    product_type: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    contract_id: PropTypes.number.isRequired,
    cost: PropTypes.arrayOf(PropTypes.number).isRequired,
    supplier: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

const Card = ({ user, contract }) => {
  const { t, formatNumber } = useTranslations();

  const {
    product_type,
    address,
    contract_id,
    cost,
    supplier: [companySupplier = '-'],
    contract_holder,
  } = contract;
  const lastInvoice = getLastInvoiceCost(cost);

  return (
    <article className={styles.root}>
      <Icon size={48} name={product_type} />
      <header className={styles.header}>
        <h3 className={styles.heading}>{address}</h3>
        <p className={styles.text}>{contract_holder}</p>
      </header>
      <footer className={styles.footer}>
        <div className={styles.block}>
          <span className={styles.value}>{formatNumber(lastInvoice)}</span>
          <span className={styles.label}>{t('dashboard:card:last_invoice')}</span>
        </div>
        <div className={styles.block}>
          <span className={styles.value}>{companySupplier}</span>
          <span className={styles.label}>{t('dashboard:card:provider')}</span>
        </div>
        <div className={styles.action}>
          <Link
            to={
              ROUTES.CONTRACT.replace(ROUTE_PARAMS.TYPE, product_type).replace(
                ROUTE_PARAMS.CONTRACT_ID,
                contract_id
              ) + ROUTE_PARAMS.BILL_VIEW
            }
          >
            <span className="visually-hidden">{t('action:view_contract')}</span>
            <Icon size={32} name={Icons.ArrowRightCircle} />
          </Link>
        </div>
      </footer>
    </article>
  );
};

Card.propTypes = CardPropTypes;

export default Card;
