import React from 'react';

import useTranslations from '../../../i18n/useTranslations';
import { ROUTES } from '../../../routes';

import { PublicLayout } from '../../../Layout';
import { Icon, Icons } from '../../Icon';
import { Button } from '../../Button';
import styles from './AccountRecoverPassSuccess.module.css';

const AccountRecoverPassSuccess = () => {
  const { t } = useTranslations();

  const handleNextStep = () => {
    console.log('resend recovery email');
  };

  return (
    <PublicLayout>
      <section className={styles.root}>
        <div className={styles.content}>
          <Icon size={96} name={Icons.RecoverPassSuccess} className={styles.icon} />
          <h1 className={styles.heading}>{t('account_recover_pass_success:heading')}</h1>
          <p className={styles.subheading}>{t('account_recover_pass_success:subheading')}</p>
        </div>
        <Button isFullWidth href={ROUTES.DASHBOARD} onClick={handleNextStep}>
          {t('action:resend_email')}
        </Button>
      </section>
    </PublicLayout>
  );
};

export default AccountRecoverPassSuccess;
