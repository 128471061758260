import { ROUTES } from '../../../routes';

const data = {
  menu: [
    {
      label: 'Aviso legal',
      href: ROUTES.LEGAL_NOTICE,
    },
    {
      label: 'Política de privacidad',
      href: ROUTES.PRIVACY_POLICY,
    },
    {
      label: 'Política de cookies',
      href: ROUTES.COOKIES_POLICY,
    },
  ],
  contact: [
    {
      label: 'hola@zenvi.com',
      href: 'mailto:hola@zenvi.com',
    },
    {
      label: '916 214 563',
      href: 'tel:+34916214563',
    },
  ],
};

export default data;
