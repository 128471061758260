import useTranslations from '../../../../i18n/useTranslations';

import styles from './Consumption.module.css';

// NOTE: Because the back-end returns an array of 12 entries (a month for each entry)
// the last month is number 11
const LAST_MONTH = 11;

const Consumption = ({ activeItem, cost, costWoZenvi }) => {
  const { t, formatNumber } = useTranslations();

  const isZero = cost === 0 && costWoZenvi === 0 && activeItem === LAST_MONTH;

  return (
    <div className={styles.root}>
      {isZero ? null : (
        <p className={styles.textDimmed}>
          <span>{formatNumber(costWoZenvi)}</span>
          <br />
          {t('dashboard:graph:previous_company')}
        </p>
      )}
      {isZero ? (
        <p className={styles.textDimmed}>
          <span>{t('general:current_month')}</span>
        </p>
      ) : (
        <p>
          <span>{formatNumber(cost)}</span>
          <br />
          {t('dashboard:graph:with_zenvi')}
        </p>
      )}
    </div>
  );
};

export default Consumption;
