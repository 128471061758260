export const composeContractBills = (contract, billsURLs) => {
  const { contract_id, product_type } = contract;

  const urlsByDate = new Map(
    billsURLs.issue_dates.map((date, index) => [date.slice(0, 7), billsURLs.urls[index]])
  );

  const formattedBills = contract.issue_date.map((date, index) => {
    return {
      id: index + 1,
      issue_date: date,
      supplier: contract.supplier[index],
      cost: contract.cost[index],
      cost_wo_zenvi: contract.cost_wo_zenvi[index],
      consumption: contract.consumption[index],
      n_days: contract.n_days[index] || 0,
      url: urlsByDate.get(date) || null,
    };
  });

  formattedBills.sort((a, b) => new Date(b.issue_date) - new Date(a.issue_date));

  return {
    contract_id,
    product_type,
    bills: formattedBills,
  };
};
