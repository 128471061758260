import { Navigate } from 'react-router-dom';

import useTranslations from '../i18n/useTranslations';
import { useAuth, useContracts, useForm } from '../context';
import { ROUTES } from '../routes';


const PrivateRoute = ({ children }) => {
  const { t } = useTranslations();
  const { isAuthenticated, loading: authLoading } = useAuth();
  const { loading: contractsLoading } = useContracts();

  if (!isAuthenticated) {
    return <Navigate to={ROUTES.ROOT} replace />;
  }

  if (authLoading || contractsLoading) {
    return <div>{t('action:loading')}</div>;
  }

  return isAuthenticated ? children : <Navigate to={ROUTES.ROOT} replace />;
};

export default PrivateRoute;
