import { useState, useMemo, useEffect } from 'react';

import useTranslations from '../../../i18n/useTranslations';

import { parseGraphData } from './adapter';
import { EmptyState } from './EmptyState';
import { Legend } from './Legend';
import { Chart } from './Chart';
import { Consumption } from './Consumption';

import styles from './Detail.module.css';

const CURRENT_DATA_SCHEMA = {
  cost: 0,
  cost_wo_zenvi: 0,
  issue_date: '',
};

const getLastValidInvoice = (inputs) => inputs.toReversed().find((value) => value.cost > 0);
const getLastValidInvoicePosition = (inputs) => {
  for (let i = inputs.length - 1; i >= 0; i--) {
    if (inputs[i].cost > 0) {
      return i;
    }
  }
  return 0;
};

const Detail = ({ contracts, automaticAdminActions }) => {
  const { t, formatDate } = useTranslations();
  const [activeItem, setActiveItem] = useState(0);
  const [isEmpty, setIsEmpty] = useState(true);

  const { data, initialData } = useMemo(() => {
    const parsedData = parseGraphData(contracts, formatDate);
    const initialState =
      parsedData.inputs.length > 0 ? getLastValidInvoice(parsedData.inputs) : CURRENT_DATA_SCHEMA;
    const initialPosition =
      parsedData.inputs.length > 0 ? getLastValidInvoicePosition(parsedData.inputs) : 0;

    setActiveItem(initialPosition);
    return { data: parsedData, initialData: initialState || CURRENT_DATA_SCHEMA };
  }, [contracts, formatDate]);

  const [currentData, setCurrentData] = useState(initialData || CURRENT_DATA_SCHEMA);

  const handleActive = (item, index) => {
    setActiveItem(index);
    setCurrentData(item || CURRENT_DATA_SCHEMA);
  };

  useEffect(() => {
    setIsEmpty(
      Array.isArray(contracts) &&
      contracts.every((contract) =>
        Array.isArray(contract.cost) && contract.cost.every((value) => value === 0)
      )
    );
  }, [contracts]);

  return (
    <article className={styles.root}>
      <div className={styles.panel}>
        <h3 className={styles.title}>{t('dashboard:graph:saving')}</h3>
        <div className={styles.graphContent}>
          {isEmpty ? (
            <EmptyState />
          ) : (
            <>
              <Chart data={data} activeItem={activeItem} handleActive={handleActive} />
              <Consumption
                activeItem={activeItem}
                cost={currentData.cost}
                costWoZenvi={currentData.cost_wo_zenvi}
              />
            </>
          )}
        </div>
      </div>
      <div className={`${styles.panel} ${styles.legend}`}>
        <h3 className={styles.title}>{t('dashboard:graph:title')}</h3>
        <button className={styles.tooltip} disabled>
          <span className="visually-hidden">{t('action:open_help')}</span>
          <span className={styles.icon}>?</span>
        </button>
        <div className={styles.content}>
          <Legend list={automaticAdminActions} />
        </div>
      </div>
    </article>
  );
};

export default Detail;