import { useEffect, useRef } from 'react';

import { Icon, Icons } from '../../../components/Icon';
import { Dialog } from './Dialog';
import styles from './DropdownButton.module.css';

export const DropdownButton = ({ user }) => {
  const dialogRef = useRef(null);

  const handleDialog = (event) => {
    event.stopPropagation();
    if (dialogRef.current && !dialogRef.current.open) {
      dialogRef.current.showModal();
    }
  };

  const handleCloseDialog = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (dialogRef.current) {
      dialogRef.current.classList.add(styles.isClosing);

      dialogRef.current.addEventListener(
        'animationend',
        () => {
          dialogRef.current.classList.remove(styles.isClosing);
          dialogRef.current.close();
        },
        { once: true }
      );
    }
  };

  useEffect(() => {
    const dialog = dialogRef.current;
    const handleClickOutside = (event) => {
      if (event.target === dialog) {
        handleCloseDialog();
      }
    };

    dialog?.addEventListener('click', handleClickOutside);

    return () => {
      dialog?.removeEventListener('click', handleClickOutside);
    };
  }, [dialogRef]);

  return (
    <>
      <button className={styles.root} onClick={handleDialog}>
        <span className={styles.username}>{user.full_name}</span>
        <Icon size={36} name={Icons.User} />
      </button>
      <Dialog ref={dialogRef} onClose={handleCloseDialog} user={user} />
    </>
  );
};
