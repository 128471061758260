import { Link } from 'react-router-dom';

import { ROUTES } from '../../routes';

import ZenviIcon from '../../assets/zenvi-lg.svg';
import styles from './PublicLayout.module.css';

const PublicLayout = ({ children }) => {
  return (
    <main className={styles.root}>
      <header className={styles.header}>
        <Link to={ROUTES.ROOT}>
          <img
            width={128}
            height={96}
            className={styles.image}
            src={ZenviIcon}
            loading="eager"
            alt=""
          />
        </Link>
      </header>
      <article className={styles.content}>{children}</article>
    </main>
  );
};

export default PublicLayout;
