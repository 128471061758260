import { useParams, useSearchParams } from 'react-router-dom';

import useTranslations from '../../i18n/useTranslations';
import { useContracts } from '../../context';

import { Icon, Icons } from '../Icon';
import { Selector } from '../Selector';
import { ContractView } from './ContractView';
import { BillView } from './BillView';
import styles from './Contract.module.css';

const BILL_VIEW = 'bill';
const CONTRACT_VIEW = 'contract';

const Contract = () => {
  const { t } = useTranslations();
  const { type, id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loading, error, getContract, contracts, bills } = useContracts();

  const contract = getContract(type, id);
  const contractBills = bills.find((b) => b.contract_id === contract?.contract_id);

  const activeView = searchParams.get('view') || BILL_VIEW;

  if (loading) return <div className={styles.root}>{t('general:loading')}</div>;
  if (error) return <div className={styles.root}>{t('general:error', { error })}</div>;
  if (!contract) return <div className={styles.root}>{t('general:contract:error')}</div>;

  const handleViewChange = (view) => {
    setSearchParams({ view });
  };

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <div className={styles.selector}>
          <Selector currentContract={contract} contracts={contracts} />
        </div>

        {activeView === BILL_VIEW ? (
          <BillView contractWithBills={contractBills} />
        ) : (
          <ContractView contract={contract} />
        )}

        <div className={styles.switcher}>
          <button
            className={`${styles.switcherButton} ${
              activeView === BILL_VIEW ? styles.isActive : ''
            }`}
            onClick={() => handleViewChange(BILL_VIEW)}
          >
            <Icon size={16} name={Icons.Currency} />
            {t('contract:bill_view:title')}
          </button>
          <button
            className={`${styles.switcherButton} ${
              activeView === CONTRACT_VIEW ? styles.isActive : ''
            }`}
            onClick={() => handleViewChange(CONTRACT_VIEW)}
          >
            <Icon size={16} name={Icons.Document} />
            {t('contract:contract_view:title')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Contract;
