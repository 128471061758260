import { useNavigate, useParams } from 'react-router-dom';

import useTranslations from '../../i18n/useTranslations';
import { useContracts } from '../../context';

import { Icon, Icons } from '../../components';
import styles from './BreadcrumbLayout.module.css';

const BreadcrumbLayout = ({ children }) => {
  const { t, formatDate } = useTranslations();
  const navigate = useNavigate();
  const { type, id, billId } = useParams();
  const { getContract, getBills } = useContracts();

  const contract = getContract(type, id);
  const bills = getBills(type, id);
  const bill = bills?.bills.find((bill) => bill.id === parseInt(billId));

  return (
    <main className={styles.root}>
      <header className={styles.header}>
        <button className={styles.backButton} onClick={() => navigate(-1)}>
          <Icon size={40} name={Icons.ChevronLeft} />
          <span>{contract?.address} &gt; </span>
        </button>
        <span>
          {t('general:bill:month', { month: formatDate(bill.issue_date, { month: 'long' }) })}
        </span>
      </header>
      <section className={styles.content}>{children}</section>
    </main>
  );
};

export default BreadcrumbLayout;
