import { useState, useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';

import { ROUTE_PARAMS, ROUTES } from '../../routes';
import useTranslations from '../../i18n/useTranslations';

import { Icon, Icons } from '../Icon';
import { Link } from '../Link';

import styles from './selector.module.css';

const SelectorPropTypes = {
  currentContract: PropTypes.shape({
    product_type: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
  }).isRequired,
  contracts: PropTypes.arrayOf(
    PropTypes.shape({
      contract_id: PropTypes.number.isRequired,
      product_type: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const Selector = memo(({ currentContract, contracts }) => {
  const { t } = useTranslations();
  const [isOpen, setIsOpen] = useState(false);
  const selectorRef = useRef(null);

  const contractsAvailable = contracts.length > 1;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const getContractPath = (contract) => {
    return ROUTES.CONTRACT.replace(ROUTE_PARAMS.TYPE, contract.product_type).replace(
      ROUTE_PARAMS.CONTRACT_ID,
      contract.contract_id
    );
  };

  return (
    <div className={styles.root} ref={selectorRef}>
      <div className={styles.header}>
        <button
          className={`${styles.selector} ${isOpen ? styles.isOpen : ''}`}
          onClick={() => setIsOpen(!isOpen)}
          disabled={!contractsAvailable}
          aria-expanded={isOpen}
          aria-label={t('action:select_contract')}
          aria-current="page"
        >
          <Icon size={48} name={currentContract.product_type} />
          <div className={styles.info}>
            <strong>{currentContract.address}</strong>
            <span>{currentContract.contract_holder}</span>
          </div>
          {contractsAvailable ? (
            <Icon className={styles.icon} size={40} name={Icons.ChevronDown} />
          ) : null}
        </button>
      </div>
      <div className={`${styles.content} ${isOpen ? styles.isOpen : ''}`}>
        {contractsAvailable
          ? contracts
              .filter((contract) => contract.contract_id !== currentContract.contract_id)
              .map((contract) => (
                <Link
                  key={`${contract.product_type}-${contract.contract_id}`}
                  className={styles.selector}
                  to={getContractPath(contract)}
                  onClick={() => setIsOpen(false)}
                >
                  <Icon size={48} name={contract.product_type} />
                  <div className={styles.info}>
                    <strong>{contract.address}</strong>
                    <span>{contract.contract_holder}</span>
                  </div>
                </Link>
              ))
          : null}
      </div>
    </div>
  );
});

Selector.propTypes = SelectorPropTypes;

export default Selector;
