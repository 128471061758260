import { useEffect, useRef } from 'react';

import useTranslations from '../../../i18n/useTranslations';
import { ROUTES } from '../../../routes';

import { Icon, Icons } from '../../Icon';
import { Link } from '../../Link';
import styles from './List.module.css';

const List = ({ contracts }) => {
  const { t } = useTranslations();
  const listRef = useRef(null);
  const listWrapperRef = useRef(null);

  useEffect(() => {
    if (listRef.current && listWrapperRef.current) {
      listRef.current.style.setProperty(
        '--list-max-block-size',
        `${listWrapperRef.current.scrollHeight}px`
      );
    }
  }, []);

  return (
    <section className={styles.root}>
      <h3 className={styles.title}>Mis contratos</h3>
      <div className={styles.content}>
        <div className={styles.listHeader}>
          <span>{t('dashboard:list:address')}</span>
          <span>{t('dashboard:list:provider')}</span>
          <span>{t('dashboard:list:last_invoice')}</span>
          <span aria-hidden>&nbsp;</span>
        </div>
        <div ref={listWrapperRef} className={styles.listWrapper}>
          <ul ref={listRef} className={styles.list}>
            {contracts.map((contract) => (
              <li key={contract.id} className={styles.item}>
                <div className={styles.address}>
                  <Icon size={32} name={contract.type} />
                  <span>{contract.address}</span>
                </div>
                <div className={styles.provider}>
                  <span>{contract.provider}</span>
                </div>
                <div className={styles.lastInvoice}>
                  <span>{contract.lastInvoice}</span>
                </div>
                <div className={styles.action}>
                  <Link to={ROUTES.CONTRACT.replace(':id', contract.id)}>
                    <Icon size={20} name={Icons.ArrowRight} />
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default List;
