import { useState } from 'react';

import useTranslations from '../../../i18n/useTranslations';
import { useNotifications } from '../../../context';

import { Icon, Icons } from '../../Icon';
import { Link } from '../../Link';
import styles from './Alerts.module.css';

const SEVERITY_ICONS = {
  high: Icons.Warning,
  medium: Icons.Info,
  low: Icons.Check,
};

const AlertItem = ({ notification, onClose }) => {
  const { t } = useTranslations();
  const { severity, message, url } = notification;
  const [exiting, setExiting] = useState(false);

  const handleClose = () => {
    setExiting(true);
    setTimeout(onClose, 300);
  };

  return (
    <div
      role="alert"
      className={`${styles.alert} ${styles[severity]} ${exiting ? styles.isExiting : ''}`}
    >
      <div className={styles.info}>
        <Icon size={24} name={SEVERITY_ICONS[severity]} />
        <p className={styles.message}>{message}</p>
      </div>
      <div className={styles.actions}>
        {url ? (
          <Link className={styles.action} to={url}>
            {severity === 'high' ? t('action:view') : t('action:view')}
          </Link>
        ) : null}
        {severity === 'high' ? null : (
          <button className={styles.close} onClick={handleClose} aria-label={t('action:close')}>
            <Icon size={24} name={Icons.Close} />
          </button>
        )}
      </div>
    </div>
  );
};

const Alerts = ({ notifications }) => {
  const { removeNotification } = useNotifications();

  return (
    <div className={styles.root}>
      {notifications.map((notification) => (
        <AlertItem
          key={notification.id}
          notification={notification}
          onClose={() => removeNotification(notification.id)}
        />
      ))}
    </div>
  );
};

export default Alerts;
