import { Navigate } from 'react-router-dom';

import useTranslations from '../i18n/useTranslations';
import { ROUTES } from '../routes';
// import { useAuth, useForm, } from '../context';
import { useAuth, useForm, useAppReady } from '../context';

const PublicRoute = ({ children }) => {
  const { t } = useTranslations();
  const { isAuthenticated, loading } = useAuth();
  const { appReady } = useAppReady();
  const { userDetailsRegistered, userIsAdmin, anyEstimatedBill, isTempPassword, routingContextReady } = useForm();

  if (!appReady) {
    return <div>{t('action:loading')}</div>;
  }

  if (isAuthenticated) {

    if (userIsAdmin) {
      return <Navigate to={ROUTES.ADMIN} replace />;
    }

    if (isTempPassword) {
      return <Navigate to={`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_PASSWORD}`} replace />;
    }

    if (!userDetailsRegistered){
      return <Navigate to={`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_NEW_TYPE}`} replace />;
    }

    if (!anyEstimatedBill){
      return <Navigate to={`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_ADDRESS}`} replace />;
    }
    return <Navigate to={ROUTES.DASHBOARD} replace />;
  }
  return children;

};

export default PublicRoute