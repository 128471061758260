import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';

import useTranslations from '../../../i18n/useTranslations';
import { ROUTES } from '../../../routes';
import services from '../../../services';
import { useAuth, useForm } from '../../../context';
import { NEW_PASSWORD_REQUEST_FORM_INPUTS } from '../../../constants';
import { newPasswordRequestSchema } from '../../../validationSchemas';

import { PublicLayout } from '../../../Layout';
import { FormikInput } from '../../Input';
import { Button } from '../../Button';
import styles from './NewPasswordRequest.module.css';
import stylesForm from '../../Form/Form.module.css';

const { newPasswordRequest } = services;

const NewPasswordRequest = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslations();

  const handleNextStep = async (values) => {
    try {
      const res = await newPasswordRequest(values);
      navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_RECOVER_PASS_SUCCESS}`);
      return;
    } catch (error) {
      navigate(ROUTES.ROOT);
    }
  };


  return (
    <PublicLayout>
      <section className={styles.root}>
        <header className={styles.header}>
          <h1 className={styles.heading}>{t('new_password_request:heading')}</h1>
        </header>
        <div className={styles.content}>
          <Formik
            initialValues={NEW_PASSWORD_REQUEST_FORM_INPUTS}
            validationSchema={newPasswordRequestSchema}
            onSubmit={handleNextStep}
          >
            {({ errors, touched }) => (
              <Form className={stylesForm.form} noValidate>
                <div className={stylesForm.formGroup}>
                  <div>
                    <FormikInput
                      type="email"
                      label="input:email"
                      name="email"
                      error={errors['email']}
                      touched={touched['email']}
                      inputMode="email"
                      required
                    />
                  </div>

                  {error && <p className={stylesForm.errorMsg}>{error}</p>}

                </div>

                <div className={stylesForm.formActions}>
                  <Button isFullWidth type="submit">
                    {t('action:continue')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </PublicLayout>
  );
};

export default NewPasswordRequest;
