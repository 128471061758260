import { useRef } from 'react';

import useTranslations from '../../../../i18n/useTranslations';

import styles from './Chart.module.css';

const Chart = ({ data, activeItem, handleActive }) => {
  const panelRef = useRef(null);
  const { t } = useTranslations();
  const renderColumn = (item, index) => {
    const hasNoValues = item.cost === 0 && item.cost_wo_zenvi === 0;
    const isLastItem = index === data.inputs.length - 1;

    const hasPreviousValues = data.inputs
      .slice(0, index)
      .some((prev) => prev.cost > 0 || prev.cost_wo_zenvi > 0);

    const isEmpty = hasNoValues && !hasPreviousValues && !isLastItem;
    const isBiMonthly = hasNoValues && hasPreviousValues;

    const ariaLabel =
      isEmpty || isBiMonthly
        ? t('dashboard:graph:chart_empty_aria_label', { date: item.full_date })
        : t('dashboard:graph:chart_aria_label', {
            date: item.full_date,
            zenvi: item.cost,
            standard: item.cost_wo_zenvi,
          });

    if (isEmpty) {
      return (
        <div
          key={item.issue_date}
          className={styles.column}
          aria-label={ariaLabel}
          role="presentation"
          style={{
            '--zenvi-cost-height': '0%',
            '--standard-cost-height': '100%',
          }}
        >
          <button
            className={`${styles.button} ${styles.buttonEmpty}`}
            disabled
            aria-hidden="true"
          />
          <span className={styles.text}>{item.issue_date}</span>
        </div>
      );
    }

    const costPercentage = Math.round((item.cost * 100) / data.max_value);
    const costWoZenviPercentage = Math.round((item.cost_wo_zenvi / data.max_value) * 100) || 100;

    return (
      <div
        key={item.issue_date}
        className={`${styles.column} ${styles.hasData} ${
          activeItem === index ? styles.isActive : ''
        } ${isBiMonthly ? styles.isBiMonthly : ''}`}
        aria-label={ariaLabel}
        aria-selected={activeItem === index}
        onClick={() => handleActive(item, index)}
        onFocus={() => handleActive(item, index)}
        onMouseEnter={() => handleActive(item, index)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleActive(item, index);
          }
        }}
        style={{
          '--zenvi-cost-height': isLastItem || isBiMonthly ? '100%' : `${costPercentage}%`,
          '--standard-cost-height':
            isLastItem || isBiMonthly ? '100%' : `${costWoZenviPercentage}%`,
        }}
      >
        <div className={`${styles.buttonWrapper} ${isLastItem && styles.isLast}`}>
          <button className={styles.button} />
          <span className={styles.value} />
        </div>
        <span className={styles.text}>{item.issue_date}</span>
      </div>
    );
  };

  return (
    <div
      ref={panelRef}
      className={styles.root}
      role="group"
      aria-label={t('dashboard:graph:chart_label')}
    >
      {data.inputs.map((item, index) => renderColumn(item, index))}
    </div>
  );
};

export default Chart;
