import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import useTranslations from '../../i18n/useTranslations';
import { useContracts, useNotifications } from '../../context';
import useMatchMedia from '../useMatchMedia';
import { TABLET_QUERY } from '../mediaqueries';
import { ROUTES } from '../../routes';

import { Card } from './Card';
import { AddContract } from './AddContract';
import { Detail } from './Detail';
import { List } from './List';
import { Alerts } from './Alerts';
import styles from './Dashboard.module.css';

const Dashboard = () => {
  const navigate = useNavigate();
  const isTablet = useMatchMedia(TABLET_QUERY);
  const { contracts, automaticAdminActions, loading } = useContracts();
  const { t } = useTranslations();
  const { notifications } = useNotifications();

  const showAsList = contracts.length > 4;

  const layoutClasses = useMemo(() => {
    return showAsList ? styles.listLayout : contracts.length === 3 ? styles.four : null;
  }, [contracts.length, showAsList]);

  const contractsList = useMemo(() => {
    if (showAsList) {
      return <List contracts={contracts} />;
    }
    return contracts.map((contract) => <Card key={contract.contract_id} contract={contract} />);
  }, [contracts, showAsList]);

  if (loading) {
    return <p>{t('general:loading:contracts')}</p>;
  }

  const handleAddContract = () => {
    navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_ADDRESS}`);
  };

  return (
    <div className={styles.root}>
      {notifications.length > 0 ? (
        <div className={styles.notificationsWrapper}>
          <Alerts notifications={notifications} />
        </div>
      ) : null}

      <div className={styles.inner}>
        <div
          className={`${styles.row} ${styles.rowContracts} ${layoutClasses}`}
          {...(!showAsList && { style: { '--columns': contracts.length } })}
        >
          {contractsList}
          {isTablet ? <AddContract onClick={handleAddContract} /> : null}
        </div>

        {!isTablet ? (
          <div className={styles.row}>
            <AddContract onClick={handleAddContract} />
          </div>
        ) : null}

        <div className={styles.row}>
          <Detail contracts={contracts} automaticAdminActions={automaticAdminActions} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
