import { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import services from '../services';
import { composeContractBills } from '../components/Contract/adapter';

const { getContracts, getLatestBillsURLs, getAutomaticAdminActions, getUserData } = services;

const ContractsContext = createContext();

export const ContractsProvider = ({ children }) => {
  const { isAuthenticated, registrationComplete } = useAuth();
  const [contracts, setContracts] = useState([]);
  const [bills, setBills] = useState([]);
  const [user, setUser] = useState(null);
  const [billsURLs, setBillsURLs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [automaticAdminActions, setAutomaticAdminActions] = useState(null);

  useEffect(() => {
    const getContractsAndBillsURLs = async () => {
      if (isAuthenticated) {
        try {
          const userData = await getUserData();
          setUser(userData);
          setLoading(true);
          setError(null);
          const automaticActions = await getAutomaticAdminActions();
          setAutomaticAdminActions(automaticActions);

          const contractsData = await getContracts();
          setContracts(contractsData);

          const allBillsURLs = await Promise.all(
            contractsData.map(async (contract) => {
              const billsURLs = await getLatestBillsURLs({
                contract_id: contract.contract_id,
                product_type: contract.product_type,
              });
              return billsURLs;
            })
          );
          setBillsURLs(allBillsURLs);

          const composedBills = contractsData.map((contract, index) => ({
            ...composeContractBills(contract, allBillsURLs[index]),
            contract_id: contract.contract_id,
            product_type: contract.product_type,
          }));

          setBills(composedBills);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      }
    };

    getContractsAndBillsURLs();
  }, [isAuthenticated, registrationComplete]);

  const getContract = (type, id) =>
    contracts.find(
      (contract) => contract.contract_id === parseInt(id) && contract.product_type === type
    );

  const getBillsURLs = (type, id) =>
    billsURLs.find((bill) => bill.contract_id === parseInt(id) && bill.product_type === type);

  const getBills = (type, id) =>
    bills.find((bill) => bill.contract_id === parseInt(id) && bill.product_type === type);

  const updateContract = (type, id, newData) => {
    setContracts((prevContracts) =>
      prevContracts.map((contract) =>
        contract.contract_id === parseInt(id) && contract.product_type === type
          ? { ...contract, ...newData }
          : contract
      )
    );
  };

  return (
    <ContractsContext.Provider
      value={{
        automaticAdminActions,
        setAutomaticAdminActions,
        contracts,
        setContracts,
        bills,
        setBills,
        billsURLs,
        setBillsURLs,
        loading,
        setLoading,
        error,
        setError,
        getContract,
        getBillsURLs,
        getBills,
        updateContract,
        user,
        setUser,
      }}
    >
      {children}
    </ContractsContext.Provider>
  );
};

export const useContracts = () => {
  const context = useContext(ContractsContext);
  if (!context) {
    throw new Error('useContracts debe usarse dentro de ContractsProvider');
  }
  return context;
};
