import useTranslations from '../../../../../i18n/useTranslations';

import styles from './Chart.module.css';

const ChartBar = ({ bill, maxValue, index, activeItem, handleActive }) => {
  const { t } = useTranslations();
  const isEmpty = bill.cost === 0;

  if (isEmpty) {
    return (
      <div key={bill.id} className={styles.chartBar}>
        <span className={styles.chartValue} style={{ '--graph-block-size': '100%' }} aria-hidden />
        <span className={`${styles.chartText} ${styles.chartTextEmpty}`} aria-hidden />
      </div>
    );
  }

  return (
    <div key={bill.id} className={styles.chartBar}>
      <button
        className={`${styles.chartValue} ${styles.withContent} ${
          activeItem === index ? styles.isActive : ''
        }`}
        style={{ '--graph-block-size': `${(bill.cost / maxValue) * 100}%` }}
        onClick={() => handleActive(index)}
        onFocus={() => handleActive(index)}
        onMouseEnter={() => handleActive(index)}
        aria-label={t('contract:bill_view:chart_bar_label', { month: bill.issue_date_month_long })}
      />
      <span className={styles.chartText}>{bill.issue_date_month}</span>
    </div>
  );
};

const Chart = ({ bills, maxValue, average, handleActive, activeItem }) => {
  const { t } = useTranslations();

  return (
    <div
      className={styles.chart}
      style={{ '--graph-average-block-size': `calc(${(average / maxValue) * 100}% + 24px)` }}
      role="group"
      aria-label={t('contract:bill_view:details')}
    >
      {bills.map((bill, index) => (
        <ChartBar
          key={bill.id}
          bill={bill}
          maxValue={maxValue}
          index={index}
          activeItem={activeItem}
          handleActive={handleActive}
        />
      ))}
    </div>
  );
};

export default Chart;
