import { Icon, Icons } from '../../../Icon';
import styles from './Feedback.module.css';

const Feedback = ({ type, heading, subheading }) => {
  return (
    <div className={`${styles.root} ${styles[type]}`}>
      <Icon size={72} name={type === 'success' ? Icons.Success : Icons.Error} />
      <div className={styles.info}>
        <p className={styles.heading}>{heading}</p>
        <p className={styles.subheading}>{subheading}</p>
      </div>
    </div>
  );
};

export default Feedback;
