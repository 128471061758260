import useTranslations from '../../../../i18n/useTranslations';

import { Icon, Icons } from '../../../Icon';
import styles from './EmptyState.module.css';

const EmptyState = () => {
  const { t } = useTranslations();

  return (
    <div className={`${styles.root} ${styles.isAbsolute}`}>
      <Icon size={40} name={Icons.Graph} />
      <p>{t('dashboard:detail:empty-state')}</p>
    </div>
  );
};

export default EmptyState;
