import { useState } from 'react';
import { Formik, Form, Field } from 'formik';

import useTranslations from '../../../../i18n/useTranslations';
import { ACCOUNT_ADDRESS_FORM_INPUTS } from '../../../../constants';
import { accountAddressSchema } from '../../../../validationSchemas';

import { Button } from '../../../Button';
import { FormikInput, RadioButtonGroup, RadioButton, Input } from '../../../Input';
import stylesForm from '../../../Form/Form.module.css';
import styles from './CorrectAddress.module.css';

const CorrectAddress = ({ data = [], onClose, onAddressesUpdated }) => {
  const { t } = useTranslations();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [correctedAddresses, setCorrectedAddresses] = useState([]);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const correctedAddress = {
        ...values,
        product_type: data[currentIndex]?.product_type || '',
      };
  
      setCorrectedAddresses((prev) => [...prev, correctedAddress]);
  
      if (currentIndex < data.length - 1) {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        onAddressesUpdated(correctedAddresses.concat(correctedAddress));
        onClose();
      }
      resetForm();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const currentAddress = data[currentIndex] || {};
  const estimatedAddress = currentAddress.estimated_address || {};

  const nonNullAddressFields = Object.fromEntries(
    Object.entries(estimatedAddress).filter(([_, value]) => value !== null)
  );

  console.log("currentAddress", currentAddress);
  console.log("chosen cups", currentAddress?.cups?.[currentAddress?.product_type]);
  const initialValues = {
    ...ACCOUNT_ADDRESS_FORM_INPUTS,
    ...nonNullAddressFields,
    cups: currentAddress?.cups?.[currentAddress?.product_type] || '',
    city: estimatedAddress.city || '',
    zip_code: estimatedAddress.zip_code || '',
  };

  return (
    <>
      <h2 className={styles.heading}>{'Valida la dirección'}</h2>
      <p className={styles.subheading}>{`Validando dirección ${currentIndex + 1} de ${
        data.length
      }`}</p>
      <div className={styles.action}>
        <Formik
          initialValues={initialValues}
          validationSchema={accountAddressSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form className={stylesForm.form} noValidate>
              <div className={stylesForm.formGroup}>
                <fieldset className={`${stylesForm.fieldset} ${stylesForm.fieldsetGrid}`}>
                  <div>
                    <Input
                      label={t('general:cups')}
                      name="cups"
                      disabled
                      value={currentAddress?.cups?.[currentAddress?.product_type]}
                    />
                  </div>
                  <div>
                    <FormikInput
                      label={'input:address_street'}
                      name="address_street"
                      error={errors['address_street']}
                      touched={touched['address_street']}
                      required
                    />
                  </div>
                  <div>
                    <FormikInput
                      label={'input:address_number'}
                      name="address_number"
                      error={errors['address_number']}
                      touched={touched['address_number']}
                      required
                    />
                  </div>
                  <div>
                    <FormikInput
                      label={'input:address_stair'}
                      name="address_stair"
                      error={errors['address_stair']}
                      touched={touched['address_stair']}
                    />
                  </div>
                  <div>
                    <FormikInput
                      label={'input:address_floor_number'}
                      name="address_floor_number"
                      error={errors['address_floor_number']}
                      touched={touched['address_floor_number']}
                    />
                  </div>
                  <div>
                    <FormikInput
                      label={'input:address_door_number'}
                      name="address_door_number"
                      error={errors['address_door_number']}
                      touched={touched['address_door_number']}
                    />
                  </div>
                  <div>
                    <FormikInput
                      label={'input:city'}
                      name="city"
                      error={errors['city']}
                      touched={touched['city']}
                      required
                    />
                  </div>
                  <div>
                    <FormikInput
                      label={'input:zip_code'}
                      name="zip_code"
                      error={errors['zip_code']}
                      touched={touched['zip_code']}
                      required
                    />
                  </div>
                </fieldset>
                <fieldset className={stylesForm.fieldset}>
                  <RadioButtonGroup
                    id="user_is_contract_holder"
                    label="input:user_is_contract_holder"
                    name="user_is_contract_holder"
                    error={errors['user_is_contract_holder']}
                    touched={touched['user_is_contract_holder']}
                  >
                    <Field
                      component={RadioButton}
                      name="user_is_contract_holder"
                      id="true"
                      label={t('option:yes')}
                    />
                    <Field
                      component={RadioButton}
                      name="user_is_contract_holder"
                      id="false"
                      label={t('option:no')}
                    />
                  </RadioButtonGroup>
                </fieldset>
              </div>
              <div className={stylesForm.formActions}>
                <Button isFullWidth type="submit">
                  {currentIndex < data.length - 1 ? 'Siguiente dirección' : 'Validar'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CorrectAddress;
