import { Icon, Icons } from '../../../Icon';
import styles from './Legend.module.css';

const Legend = ({ list }) => {
  if (!Array.isArray(list) || list.length === 0) {
    return null;
  }
  return (
    <div className={styles.root}>
      <ul className={styles.list}>
        {list.map((entry) => (
          <li key={entry.id} className={styles.item}>
            <Icon size={16} name={Icons.CheckCircle} />
            <span>{entry.title}</span>
            <time className={styles.time} dateTime={entry.date}>
              {entry.date}
            </time>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Legend;
