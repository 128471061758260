export const Logo = () => (
  <svg width="97" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
      <rect y="3.292" width="25.587" height="25.587" rx="12.794" fill="#FE4902" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m14.404 16.429-3.134 4.393c-.272.373-.7.599-1.158.599-1.159 0-1.835-1.306-1.159-2.247l1.29-1.809H7.375a1.378 1.378 0 0 1 0-2.755h2.88l-1.303-1.828c-.676-.941 0-2.247 1.159-2.247.459 0 .894.225 1.158.598l3.142 4.394a.783.783 0 0 1-.008.902Zm5.166 0-3.134 4.393c-.272.373-.7.599-1.159.599-1.158 0-1.835-1.306-1.158-2.247l2.278-3.196-2.278-3.196c-.677-.941 0-2.247 1.159-2.247.458 0 .894.225 1.158.598l3.142 4.394a.783.783 0 0 1-.008.902Z"
        fill="#fff"
      />
      <path
        d="M93.253 7.06V2.937h4.122v4.121h-4.122Zm0 16.35V8.406h4.122V23.41h-4.122ZM83.332 23.41 77.424 8.406h4.451l3.243 8.986 3.243-8.986h4.451L86.904 23.41h-3.572ZM63.825 23.41V8.406h3.847v1.841c.77-1.484 2.308-2.17 4.204-2.17 3.38 0 5.689 2.28 5.689 5.688v9.645h-4.122v-8.766c0-1.731-1.127-2.858-2.748-2.858-1.622 0-2.748 1.127-2.748 2.858v8.766h-4.122ZM55.627 23.74c-4.754 0-7.832-3.518-7.832-7.86 0-4.479 3.27-7.804 7.557-7.804 4.754 0 7.42 3.243 7.42 7.392 0 .605-.056 1.182-.193 1.594H52.082c.247 1.896 1.566 3.105 3.6 3.105 1.456 0 2.528-.632 3.105-1.649l3.297 1.567c-.906 2.198-3.38 3.655-6.457 3.655Zm-.275-12.366c-1.621 0-2.803.934-3.188 2.665h6.238c-.165-1.594-1.346-2.665-3.05-2.665Z"
        fill="#181818"
      />
      <path
        d="M35.04 23.41v-3.298l7.392-8.134h-7.035V8.406h12.53v3.298l-7.172 8.134h7.2v3.572H35.04Z"
        fill="#181818"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h97v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
