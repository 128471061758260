const MIN_IBAN_LENGTH = 4;
const IBAN_LENGTH = 16;

export const maskIBAN = (iban) => {
  if (!iban){
    return "-"
  }
  if (iban.length <= MIN_IBAN_LENGTH) {
    return iban.padStart(IBAN_LENGTH, '*');
  }

  const suffix = iban.slice(iban.length - MIN_IBAN_LENGTH);
  const rest = iban.slice(MIN_IBAN_LENGTH).replace(/\d/g, '*');
  return rest + suffix;
};
