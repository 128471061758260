import React, { forwardRef, useState, memo } from 'react';
import { Formik, Form, useFormikContext } from 'formik';

import useTranslations from '../../../../../i18n/useTranslations';

import { UPDATE_ACCOUNT_PASSWORD } from '../../../../../constants';
import { updateAccountPasswordSchema } from '../../../../../validationSchemas';
import services from '../../../../../services';
import { Icons, Icon, Button } from '../../../../../components';
import { FormikInput } from '../../../../../components/Input';
import stylesForm from '../../../../../components/Form/Form.module.css';
import styles from './UpdatePassword.module.css';

const { updateUserPassword } = services;

const SuccessMessage = memo(() => {
  const { t } = useTranslations();
  return (
    <div className={`${styles.feedback} ${styles.success}`}>
      <Icon size={24} name={Icons.Check} />
      <p>{t('general:password:success')}</p>
    </div>
  );
});

const ErrorMessage = memo(() => {
  const { t } = useTranslations();
  return (
    <div className={`${styles.feedback} ${styles.error}`}>
      <Icon size={24} name={Icons.Close} />
      <p>{t('general:password:error')}</p>
    </div>
  );
});

const UpdatePassword = forwardRef(({ onClose }, ref) => {
  const { t } = useTranslations();
  const [feedback, setFeedback] = useState('');
  const [passwordValidations, setPasswordValidations] = useState({
    minLength: false,
    uppercase: false,
    specialChar: false,
  });

  const handleClickOutside = (event) => {
    if (event.target === ref.current) {
      handleClose();
    }
  };

  const handleClose = () => {
    setFeedback('');
    onClose();
  };

  const handleNextStep = async (values, { resetForm }) => {
    try {
      const data = {
        current_password: values.password,
        new_password: values.new_password,
      };

      await updateUserPassword(data);
      setFeedback('success');
      resetForm();
    } catch (error) {
      setFeedback('error');
    }
  };

  const FormikPasswordValidator = () => {
    const { values } = useFormikContext();

    React.useEffect(() => {
      const password = values.new_password || '';
      const newValidations = {
        minLength: password.length >= 8,
        uppercase: /[A-Z]/.test(password),
        specialChar: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password),
      };

      if (JSON.stringify(newValidations) !== JSON.stringify(passwordValidations)) {
        setPasswordValidations(newValidations);
      }
    }, [values.new_password]);

    return null;
  };

  return (
    <dialog
      ref={ref}
      className={styles.root}
      onKeyDown={(event) => event.key === 'Escape' && onClose(event)}
      onClick={handleClickOutside}
    >
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <button className={styles.close} type="button" onClick={onClose}>
            <span className="visually-hidden">{t('action:close')}</span>
            <Icon size={32} name={Icons.Close} />
          </button>
        </header>
        <div className={styles.content}>
          <h2 className={styles.heading}>{t('profile:password:create_new')}</h2>
          <p className={styles.subheading}>{t('profile:password:requirements')}</p>
          <div className={styles.form}>
            <Formik
              initialValues={UPDATE_ACCOUNT_PASSWORD}
              validationSchema={updateAccountPasswordSchema}
              onSubmit={handleNextStep}
            >
              {({ errors, touched }) => (
                <>
                  <FormikPasswordValidator />
                  {feedback === 'error' && <ErrorMessage />}
                  {feedback === 'success' && <SuccessMessage />}
                  <Form className={stylesForm.form} noValidate>
                    <div className={stylesForm.formGroup}>
                      <div>
                        <FormikInput
                          type="password"
                          label="input:password"
                          name="password"
                          error={errors['password']}
                          touched={touched['password']}
                          inputMode="text"
                          required
                        />
                      </div>
                      <div>
                        <FormikInput
                          type="password"
                          label="input:new_password"
                          name="new_password"
                          error={errors['new_password']}
                          touched={touched['new_password']}
                          inputMode="text"
                          required
                        />
                      </div>
                      <div>
                        <FormikInput
                          type="password"
                          label="input:confirm_password"
                          name="confirm_new_password"
                          error={errors['confirm_new_password']}
                          touched={touched['confirm_new_password']}
                          inputMode="text"
                          required
                        />
                      </div>
                    </div>
                    <div className={stylesForm.formActions}>
                      <Button isFullWidth type="submit">
                        {t('action:continue')}
                      </Button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </dialog>
  );
});

export default UpdatePassword;
