import React from 'react';

import useTranslations from '../../../i18n/useTranslations';
import { ROUTES } from '../../../routes';

import PlugIcon from '../../../assets/plug-icon.svg';
import { PublicLayout } from '../../../Layout';
import { Button } from '../../Button';
import styles from './AccountSuccess.module.css';
import { useAuth } from '../../../context';

const AccountSuccess = () => {
  const { t } = useTranslations();
  const { setRegistrationComplete } = useAuth();

  const handleNextStep = async (e) => {
    setRegistrationComplete(true)
  };
  return (
    <PublicLayout>
      <div className={styles.root}>
        <img width="80" height="80" src={PlugIcon} alt="" aria-hidden />
        <div className={styles.content}>
          <h1 className={styles.heading}>{t('account_success:heading')}</h1>
          <p className={styles.subheading}>{t('account_success:subheading')}</p>
        </div>
        <Button isFullWidth href={ROUTES.DASHBOARD} onClick={handleNextStep}>
          {t('action:go_to_profile')}
        </Button>
      </div>
    </PublicLayout>
  );
};

export default AccountSuccess;
