import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';

import useTranslations from '../../../i18n/useTranslations';
import { ROUTES } from '../../../routes';
import {
  ACCOUNT_NEW_USER_FORM_INPUTS_PERSON,
  ACCOUNT_NEW_USER_FORM_INPUTS_COMPANY,
} from '../../../constants';
import services from '../../../services';
import { useForm } from '../../../context';
import { accountNewUserSchema, accountNewUserCompanySchema } from '../../../validationSchemas';

import { Button } from '../../Button';
import { FormikInput } from '../../Input';
import { FormikCheckbox } from '../../Input';
import { Stepper } from '../Stepper';
import stylesForm from '../../Form/Form.module.css';
import styles from './AccountNewUser.module.css';

const { saveUserInfo, checkIfUserDetailsRegistered } = services;

const AccountNewUser = () => {
  const { formData, updateFormData, setUserDetailsRegistered } = useForm();
  const navigate = useNavigate();
  const { t } = useTranslations();

  const handleNextStep = async (values) => {
    try {
      const updatedFormData = { ...formData, ...values };
      await saveUserInfo(updatedFormData);
      updateFormData(updatedFormData)
      const resUserDetailsRegistered = await checkIfUserDetailsRegistered(updatedFormData);
      setUserDetailsRegistered(resUserDetailsRegistered.user_details_registered)
      navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_ADDRESS}`);
    } catch (err) {
      console.log(err);
    }
  };

  const isCompany = formData.is_company;

  return (
    <Stepper currentStep={1}>
      <section className={styles.root}>
        <header className={styles.header}>
          <h1 className={styles.heading}>{t('account_personal_data:heading')}</h1>
        </header>
        <div className={styles.content}>
          <Formik
            initialValues={
              isCompany ? ACCOUNT_NEW_USER_FORM_INPUTS_COMPANY : ACCOUNT_NEW_USER_FORM_INPUTS_PERSON
            }
            validationSchema={isCompany ? accountNewUserCompanySchema : accountNewUserSchema}
            onSubmit={handleNextStep}
          >
            {({ errors, touched, setFieldValue }) => (
              <Form className={stylesForm.form} noValidate>
                <div className={stylesForm.formGroup}>
                  {isCompany ? (
                    <fieldset className={styles.fieldset}>
                      <legend className={stylesForm.legend}>{t('input:company_data')}</legend>
                      <div>
                        <FormikInput
                          label="input:nif"
                          name="nif"
                          error={errors['nif']}
                          touched={touched['nif']}
                          required
                        />
                      </div>
                      <div>
                        <FormikInput
                          label="input:corporate_name"
                          name="corporate_name"
                          error={errors['corporate_name']}
                          touched={touched['corporate_name']}
                          required
                        />
                      </div>
                      <div>
                        <FormikInput
                          label="input:office_address"
                          name="office_address"
                          error={errors['office_address']}
                          touched={touched['office_address']}
                          required
                        />
                      </div>
                    </fieldset>
                  ) : null}

                  <fieldset className={styles.fieldset}>
                    {isCompany ? (
                      <legend className={stylesForm.legend}>{t('input:personal_data')}</legend>
                    ) : null}
                    <div>
                      <FormikInput
                        label="input:name"
                        name="name"
                        error={errors['name']}
                        touched={touched['name']}
                        required
                      />
                    </div>
                    <div>
                      <FormikInput
                        label="input:first_surname"
                        name="first_surname"
                        error={errors['first_surname']}
                        touched={touched['first_surname']}
                        required
                      />
                    </div>
                    <div>
                      <FormikInput
                        label="input:second_surname"
                        name="second_surname"
                        error={errors['second_surname']}
                        touched={touched['second_surname']}
                        required
                      />
                    </div>
                    <div className={stylesForm.formRow}>
                      <div>
                        <FormikInput
                          label="input:id_number"
                          name="id_number"
                          error={errors['id_number']}
                          touched={touched['id_number']}
                          required
                        />
                      </div>
                      <div>
                        <FormikInput
                          label="input:phone_number"
                          name="phone_number"
                          error={errors['phone_number']}
                          touched={touched['phone_number']}
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <FormikCheckbox
                        label={
                          <span>
                            Acepto los{' '}
                            <a
                              href="https://storage.googleapis.com/shareable-content/web_legal_docs.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              términos legales
                            </a>
                          </span>
                        }
                        name="is_privacy_policy_checked"
                        onChange={(e) => {
                          setFieldValue('is_privacy_policy_checked', e.target.checked);
                          updateFormData({ is_privacy_policy_checked: e.target.checked });
                        }}
                        checked={formData.is_privacy_policy_checked}
                        error={errors['is_privacy_policy_checked']}
                        touched={touched['is_privacy_policy_checked']}
                        required
                      />
                    </div>
                  </fieldset>
                </div>
                <div className={stylesForm.formActions}>
                  <Button isFullWidth type="submit">
                    {t('action:continue')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </Stepper>
  );
};

export default AccountNewUser;
