import { forwardRef } from 'react';

import useTranslations from '../../../../../i18n/useTranslations';

import { Icons, Icon, Button } from '../../../../../components';
import styles from './Confirm.module.css';

const Confirm = forwardRef(({ onConfirm, onClose }, ref) => {
  const { t } = useTranslations();

  const handleClickOutside = (event) => {
    if (event.target === ref.current) {
      onClose();
    }
  };

  return (
    <dialog
      ref={ref}
      className={styles.root}
      onKeyDown={(event) => event.key === 'Escape' && onClose(event)}
      onClick={handleClickOutside}
    >
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <button className={styles.close} type="button" onClick={onClose}>
            <span className="visually-hidden">{t('action:close')}</span>
            <Icon size={32} name={Icons.Close} />
          </button>
        </header>
        <div className={styles.content}>
          <h2 className={styles.heading}>{t('profile:confirm_logout')}</h2>
          <div className={styles.actions}>
            <Button isGosht onClick={onClose}>
              {t('action:cancel')}
            </Button>
            <Button onClick={onConfirm}>{t('action:close')}</Button>
          </div>
        </div>
      </div>
    </dialog>
  );
});

export default Confirm;
