import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';

import useTranslations from '../../../i18n/useTranslations';
import { ROUTES } from '../../../routes';
import services from '../../../services';
import { useForm, useContracts } from '../../../context';

import { Button } from '../../Button';
import { UploadFile } from './UploadFile';
import { Stepper } from '../Stepper';
import { ContractDialog } from './ContractDialog';
import { CorrectAddress } from './CorrectAddress';
import { Feedback } from './Feedback';
import { RemoveButton } from './RemoveButton';
import { Callout } from './Callout';

import stylesForm from '../../Form/Form.module.css';
import styles from './AccountAddress.module.css';

const { computeBillEstimates, getComputedBillEstimates, getUserData } = services;

const formatAddress = (address) => {
  const {
    address_street,
    address_number,
    address_stair,
    address_floor_number,
    address_door_number,
  } = address;

  return [address_street, address_number, address_stair, address_floor_number, address_door_number]
    .filter(Boolean)
    .join(', ');
};

const AccountAddress = () => {
  const [userName, setUserName] = useState(null);
  const [correctedAddresses, setCorrectedAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addressesIsLoading, setAddressesIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { formData, setContractResult } = useForm();
  const { user, setUser } = useContracts();
  const [countdownTime, setCountdownTime] = useState(0);
  const [action, setAction] = useState(null);
  const [dialogData, setDialogData] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslations();

  const uploadDocumentRef = useRef(null);
  const dialogRef = useRef(null);

  const handleReset = () => {
    if (uploadDocumentRef.current) {
      uploadDocumentRef.current.resetFiles();
    }
  };

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        if (!formData.name) {
          let userData = user;
          if (!userData) {
            userData = await getUserData();
            setUser(userData);
          }
          setUserName({
            name: userData.full_name,
            first_surname: '',
            second_surname: '',
          });
        } else {
          setUserName({
            name: formData.name,
            first_surname: formData.first_surname,
            second_surname: formData.second_surname,
          });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserName();
  }, [formData, user, setUser]);

  const handleFileUpload = async (e) => {
    const files = e.target.files || [];
    if (files.length > 0) {
      try {
        setAddressesIsLoading(true);
        const estimatedAddresses = await computeBillEstimates(files);
        setAddressesIsLoading(false);
        setDialogData(estimatedAddresses);
        setAction('correctAddress');
        dialogRef.current?.showModal();
      } catch (error) {
        console.error('Error computing bill estimates:', error);
      }
    }
  };

  const removeAddress = (index) => {
    setCorrectedAddresses((prevAddresses) => prevAddresses.filter((_, i) => i !== index));
  };

  const handleDialogClose = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (dialogRef.current) {
      dialogRef.current.close();
      setAction(null);
      setDialogData(null);
    }
  };

  const handleAddressesUpdated = (updatedAddress) => {
    setCorrectedAddresses((prev) => [...prev, ...updatedAddress]);
    handleReset();
  };

  const handleNextStep = async () => {
    setLoading(true);
    setCountdownTime(correctedAddresses.length * 5);

    try {
      const formattedCorrectedAddresses = correctedAddresses.map((address) => ({
        ...address,
        cups: address.cups,
      }));
      const result = await getComputedBillEstimates(formattedCorrectedAddresses);
      setContractResult(result.data);
      setLoading(false);
      setCountdownTime(null);
      navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_ESTIMATED_RESULTS}`);
    } catch (err) {
      console.error('Error during next step:', err);
      setLoading(false);
      setError(true);
      navigate(ROUTES.DASHBOARD);
    }
  };

  const renderActionComponent = () => {
    switch (action) {
      case 'correctAddress':
        return (
          <CorrectAddress
            data={dialogData}
            onClose={handleDialogClose}
            onAddressesUpdated={handleAddressesUpdated}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Stepper currentStep={2}>
      <section className={styles.root}>
        <header className={styles.header}>
          <h1 className={styles.heading}>
            {loading
              ? t('account_address:loading_heading')
              : error
              ? t('account_address:error_heading')
              : t('account_address:heading')}
          </h1>
        </header>
        <div className={styles.content}>
          {loading ? (
            <Feedback type="loading" countdownTime={countdownTime} />
          ) : error ? (
            <Feedback type="error" text={t('account_address:error_text')} />
          ) : (
            <>
              {correctedAddresses.length > 0 && (
                <div className={styles.records}>
                  {correctedAddresses.map((address, index) => (
                    <React.Fragment key={`${address.address_street}-${address.address_number}`}>
                      {address.user_is_contract_holder === 'false' ? (
                        <Callout name={`${userName.name} ${userName.first_surname}`} />
                      ) : null}
                      <RemoveButton
                        key={index}
                        heading={formatAddress(address)}
                        text={`${userName.name} ${userName.first_surname} ${userName.second_surname}`}
                        handleClick={() => removeAddress(index)}
                      />
                    </React.Fragment>
                  ))}
                </div>
              )}

              <Formik initialValues={{}} onSubmit={handleNextStep}>
                {({ errors, touched, setFieldValue, setFieldError, setTouched }) => (
                  <Form className={stylesForm.form} noValidate>
                    <div className={stylesForm.formGroup}>
                      <fieldset className={stylesForm.fieldset}>
                        <UploadFile
                          ref={uploadDocumentRef}
                          name="file"
                          error={errors['file']}
                          touched={touched['file']}
                          handleFileUpload={(e) => handleFileUpload(e, setFieldValue)}
                          setError={(message) => setFieldError('file', message)}
                          setTouched={setTouched}
                          isLoading={addressesIsLoading}
                        />
                      </fieldset>
                    </div>
                    {correctedAddresses.length > 0 && (
                      <div className={styles.formActions}>
                        <Button isFullWidth type="submit">
                          {t('action:continue')}
                        </Button>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </>
          )}
        </div>
        <ContractDialog ref={dialogRef} onClose={handleDialogClose}>
          {renderActionComponent()}
        </ContractDialog>
      </section>
    </Stepper>
  );
};

export default AccountAddress;
