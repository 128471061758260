import styles from './Card.module.css';

const CardPromotedText = ({ isBestOffer, title, text }) => {
  const typeClass = isBestOffer ? styles.promotedTextBest : styles.promotedTextDefault;
  return (
    <h3 className={`${styles.promotedText} ${typeClass}`}>
      <strong>{title}</strong>
      {text ? <span>{text}</span> : null}
    </h3>
  );
};

export default CardPromotedText;
