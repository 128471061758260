import React, { createContext, useContext, useState, useEffect } from 'react';

import { useAuth } from '.';
import services from '../services';

const { checkIfUserDetailsRegistered, isUserAdmin, checkIfAnyEstimatedBill, isUserOnboarded, isIBANRegistered, checkEmailTempPassword } = services;

export const FormContext = createContext();

const FORM_DATA_KEY = 'formData';
const CONTRACT_RESULT_KEY = 'contractResult';
const SIGN_URL_KEY = 'signUrl';

export const FormProvider = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  const [formData, setFormData] = useState({
    name: '',
    first_surname: '',
    second_surname: '',
    id_number: '',
    phone_number: '',
    is_company: false,
    nif: '',
    corporate_name: '',
    office_address: '',
    new_password: '',
    is_privacy_policy_checked: false,
  });

  const [contractResult, setContractResult] = useState(null);
  const [signUrl, setSignUrl] = useState(null);
  const [userDetailsRegistered, setUserDetailsRegistered] = useState(false);
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [userOnboarded, setUserOnboarded] = useState(false);
  const [ibanRegistered, setIbanRegistered] = useState(false);
  const [isTempPassword, setIsTempPassword] = useState(false);
  const [anyEstimatedBill, setAnyEstimatedBill] = useState(false);
  const [routingContextReady, setRoutingContextReady] = useState(false);

  useEffect(() => {
    const storedFormData = localStorage.getItem(FORM_DATA_KEY);
    const storedContractResult = localStorage.getItem(CONTRACT_RESULT_KEY);
    const storedSignUrl = localStorage.getItem(SIGN_URL_KEY);

    if (storedFormData) {
      setFormData(JSON.parse(storedFormData));
    }
    if (storedContractResult) {
      setContractResult(JSON.parse(storedContractResult));
    }
    if (storedSignUrl) {
      setSignUrl(JSON.parse(storedSignUrl));
    }
  }, []);

  const refreshContext = async () => {
    try {
      setRoutingContextReady(false); // Signal that context is being updated
  
      const [
        resUserIsAdmin,
        resUserDetailsRegistered,
        resAnyEstimatedBill,
        resIsUserOnboarded,
        resIbanRegistered,
        resTempPassword,
      ] = await Promise.all([
        isUserAdmin(),
        checkIfUserDetailsRegistered(),
        checkIfAnyEstimatedBill(),
        isUserOnboarded(),
        isIBANRegistered(),
        checkEmailTempPassword(),
      ]);
  
      // Update state
      setUserIsAdmin(resUserIsAdmin.is_admin);
      setUserDetailsRegistered(resUserDetailsRegistered.user_details_registered);
      setAnyEstimatedBill(resAnyEstimatedBill.any_estimated_bill);
      setUserOnboarded(resIsUserOnboarded.success);
      setIbanRegistered(resIbanRegistered.success);
      setIsTempPassword(resTempPassword);
  
      console.log("Context refreshed successfully.");
  
      // Return updated values
      return {
        userIsAdmin: resUserIsAdmin.is_admin,
        userDetailsRegistered: resUserDetailsRegistered.user_details_registered,
        anyEstimatedBill: resAnyEstimatedBill.any_estimated_bill,
        userOnboarded: resIsUserOnboarded.success,
        ibanRegistered: resIbanRegistered.success,
        isTempPassword: resTempPassword,
      };
    } catch (error) {
      console.error('Error refreshing context:', error);
      setRoutingContextReady(true); // Prevent indefinite loading
      return Promise.reject(error); // Ensure caller knows of the error
    } finally {
      setRoutingContextReady(true); // Signal that context is updated
    }
  };

  useEffect(() => {
    if (isAuthenticated && !loading) {
      refreshContext();
    }
  }, [isAuthenticated, loading]);

  useEffect(() => {
    localStorage.setItem(FORM_DATA_KEY, JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    if (contractResult) {
      localStorage.setItem(CONTRACT_RESULT_KEY, JSON.stringify(contractResult));
    }
  }, [contractResult]);

  useEffect(() => {
    if (signUrl) {
      localStorage.setItem(SIGN_URL_KEY, JSON.stringify(signUrl));
    }
  }, [signUrl]);

  const updateFormData = (newData) => {
    setFormData((prevData) => ({ ...prevData, ...newData }));
  };

  return (
    <FormContext.Provider
    value={{
      formData,
      updateFormData,
      contractResult,
      setContractResult,
      signUrl,
      setSignUrl,
      userDetailsRegistered,
      setUserDetailsRegistered,
      userIsAdmin,
      setUserIsAdmin,
      anyEstimatedBill,
      setAnyEstimatedBill,
      userOnboarded,
      setUserOnboarded,
      ibanRegistered,
      setIbanRegistered,
      isTempPassword,
      setIsTempPassword,
      routingContextReady,
      setRoutingContextReady,
      refreshContext,
    }}
  >
      {children}
    </FormContext.Provider>
  );
};

export const useForm = () => useContext(FormContext);
