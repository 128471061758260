import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './Button.module.css';

const ButtonPropTypes = {
  type: PropTypes.string,
  href: PropTypes.string,
  isFullWidth: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isGosht: PropTypes.bool,
  isNegative: PropTypes.bool,
  target: PropTypes.string,
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

const Button = ({
  type = 'button',
  href,
  onClick,
  children,
  isFullWidth = false,
  isSecondary = false,
  isGosht = false,
  isNegative = false,
  target,
  ariaLabel,
  ...rest
}) => {
  const Component = href ? Link : 'button';
  const isLink = Component === Link;
  const classNames = `${styles.root} ${isFullWidth ? styles.isFullWidth : ''} ${
    isSecondary ? styles.isSecondary : ''
  } ${isGosht ? styles.isGosht : ''} ${isNegative ? styles.isNegative : ''}`;

  if (isLink) {
    return (
      <Link
        to={href}
        className={classNames}
        onClick={onClick}
        target={target}
        aria-label={ariaLabel}
        {...rest}
      >
        {children}
      </Link>
    );
  }

  return (
    <button type={type} className={classNames} onClick={onClick} aria-label={ariaLabel} {...rest}>
      {children}
    </button>
  );
};

Button.propTypes = ButtonPropTypes;

export default Button;
