import { useCallback } from 'react';
import { useIntl } from 'react-intl';

const useTranslations = () => {
  const intl = useIntl();

  const formatMessage = useCallback(
    (key, values = {}) => intl.formatMessage({ id: key, defaultMessage: key }, values),
    [intl]
  );

  const formatNumber = useCallback(
    (figure) => {
      const format = (number) =>
        new Intl.NumberFormat(intl.locale, {
          signDisplay: 'never',
          maximumFractionDigits: 2,
          useGrouping: 'always',
          style: 'currency',
          currency: 'EUR',
        }).format(number);

      return format(figure);
    },
    [intl.locale]
  );

  const formatDate = useCallback(
    (date, options = {}) => {
      return new Date(date).toLocaleDateString(intl.locale, { month: 'short', ...options });
    },
    [intl.locale]
  );

  return {
    t: formatMessage,
    locale: intl.locale,
    formatNumber,
    formatDate,
  };
};

export default useTranslations;
