export const Energies = Object.freeze({
  Light: 'light',
  Gas: 'gas',
});

export const Icons = Object.freeze({
  ArrowDown: 'arrow-down',
  ArrowRight: 'arrow-right',
  ArrowRightCircle: 'arrow-right-circle',
  Check: 'check',
  CheckCircle: 'check-circle',
  ChevronDown: 'chevron-down',
  ChevronLeft: 'chevron-left',
  Close: 'close',
  Currency: 'currency',
  Document: 'document',
  Download: 'download',
  Error: 'error',
  Graph: 'graph',
  Info: 'info',
  Loader: 'loader',
  Logout: 'logout',
  Notifications: 'notifications',
  Pattern: 'pattern',
  Plus: 'plus',
  RecoverPassSuccess: 'recover-pass-success',
  Search: 'search',
  Success: 'success',
  User: 'user',
  Warning: 'warning',
});
