import useTranslations from '../../../i18n/useTranslations';

import { Icon, Icons } from '../../Icon';
import styles from './AddContract.module.css';

const AddContract = ({ onClick }) => {
  const { t } = useTranslations();

  return (
    <button className={styles.root} onClick={onClick}>
      <div className={styles.icon}>
        <Icon size={24} name={Icons.Plus} />
      </div>
      <span className={styles.text}>{t('action:add_contract')}</span>
    </button>
  );
};

export default AddContract;
