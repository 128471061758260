import useTranslations from '../../../../i18n/useTranslations';

import { Button } from '../../../Button';
import { Checkbox } from '../../../Input';
import styles from './Confirm.module.css';

const CardConfirm = ({ isOpen, id, onCancel, onConfirm, isChecked, onCheckboxChange }) => {
  const { t } = useTranslations();

  return (
    <div className={`${styles.root} ${isOpen ? styles.isOpen : ''}`} inert={isOpen ? null : 'true'}>
      <h3 className={styles.title}>{t('account_estimated_results:card:confirm:heading')}</h3>
      <Checkbox id={id} isOpen={isOpen} isChecked={isChecked} onCheckboxChange={onCheckboxChange} />
      <div className={styles.actions}>
        <Button isGosht onClick={onCancel} disabled={!isOpen}>
          {t('general:no')}
        </Button>
        <Button isNegative onClick={onConfirm} disabled={!isOpen}>
          {t('general:yes')}
        </Button>
      </div>
    </div>
  );
};

export default CardConfirm;
