import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ROUTES, ROUTE_PARAMS } from '../../routes';
import useTranslations from '../../i18n/useTranslations';
import { useContracts } from '../../context';

import { Icon, Icons } from '../Icon';
import { Button } from '../Button';
import { Link } from '../Link';
import styles from './Bill.module.css';

const Bill = () => {
  const { t, formatNumber, formatDate } = useTranslations();
  const { type, id, billId } = useParams();
  const { loading, error, getContract, getBills } = useContracts();

  const contract = getContract(type, id);
  const bills = getBills(type, id);
  const bill = bills.bills.find((bill) => bill.id === parseInt(billId));

  if (loading) return <div className={styles.root}>{t('general:loading')}</div>;
  if (error) return <div className={styles.root}>{t('general:error', { error })}</div>;
  if (!contract) return <div className={styles.root}>{t('general:bill:error')}</div>;

  return (
    <article className={styles.root}>
      <div className={styles.content}>
        <div className={styles.overview}>
          <Link
            className={styles.overviewLink}
            to={
              ROUTES.CONTRACT.replace(ROUTE_PARAMS.TYPE, type).replace(
                ROUTE_PARAMS.CONTRACT_ID,
                id
              ) + ROUTE_PARAMS.CONTRACT_VIEW
            }
          >
            <div className={styles.overviewLinkContent}>
              <strong>{formatNumber(bill.cost)}</strong>
            </div>
            <span className={styles.overviewLinkLabel}>{t('action:view_contract')}</span>
          </Link>
        </div>
        <div className={styles.address}>
          <p className={styles.addressInfo}>
            <strong>{contract.address}</strong>
            <span>{contract.contract_holder}</span>
          </p>
        </div>
        <div className={styles.date}>
          <time className={styles.dateInfo} dateTime={bill.issue_date}>
            <strong>{formatDate(bill.issue_date, { month: 'long' })}</strong>
            <span>{t('general:billing_days', { days: bill.n_days })}</span>
          </time>
        </div>
        <div className={styles.consumption}>
          <h3 className={styles.title}>{t('bill:consumption:title')}</h3>
          <div className={styles.consumptionContent}>
            <div className={styles.consumptionBox}>
              <p className={`${styles.consumptionText} ${styles.consumptionTextRight}`}>
                <strong>{formatNumber(bill.cost_wo_zenvi)}</strong>
                <span>{t('bill:consumption:previous_company')}</span>
              </p>
              <div className={styles.consumptionBoxColumn} aria-hidden />
            </div>
            <div className={styles.consumptionBox}>
              <div
                className={`${styles.consumptionBoxColumn} ${styles.consumptionBoxColumnZenvi}`}
                aria-hidden
                style={{
                  '--consumption-column-height': `${(bill.cost / bill.cost_wo_zenvi) * 100}%`,
                }}
              />
              <p className={styles.consumptionText}>
                <strong>{formatNumber(bill.cost)}</strong>
                <span>{t('bill:consumption:with_zenvi')}</span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.save}>
          <h3 className={styles.title}>{t('bill:save:title')}</h3>
          <div className={styles.saveContent}>
            <p className={styles.saveInfo}>
              <strong>{formatNumber(bill.cost / bill.n_days)}</strong>
              <span>{t('bill:save:daily_average')}</span>
            </p>
            <p className={styles.saveInfo}>
              <strong>
                {bill.consumption} {t('general:kwh')}
              </strong>
              <span>{t('bill:save:total_consumption')}</span>
            </p>
            <p className={styles.saveInfo}>
              <strong>
                {formatNumber(bill.cost / bill.consumption)}/{t('general:kwh')}
              </strong>
              <span>{t('bill:save:average_price')}</span>
            </p>
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <Button href={bill.url} target="_blank" rel="noopener noreferrer">
          {t('action:download_bill')}
          <Icon size={20} name={Icons.Download} />
        </Button>
      </div>
    </article>
  );
};

export default Bill;
