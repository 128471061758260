import { forwardRef } from 'react';

import styles from './ContractDialog.module.css';

const ContractDialog = forwardRef(({ children }, ref) => (
  <dialog ref={ref} className={styles.root}>
    <div className={styles.wrapper}>
      <div className={styles.content}>{children}</div>
    </div>
  </dialog>
));

export default ContractDialog;
